<template>
    <div class="home">
      <div class="container">
        <Top :activeTitle="activeTitle" :list="list" />

        <div class="mall">
          <div class="personal_head clearFix" v-if="userShow">
            <div class="head_icon"><img src="../assets/image/personal.png" alt=""></div>
            <div class="head_input clearFix"><input  v-model="user_id" placeholder="กรุณากรอกID" type="text"><div class="head_button" @click="queryUserInfo">ยืนยัน</div></div>
          </div>
          <div class="user" v-if="!userShow">
            <div class="user_icon"><img :src="this.user_info.face_md5 ? this.user_info.face_md5 : require('@/assets/image/personal.png')" alt=""></div>
            <div class="user_detail">
              <div class="user_name">{{ this.user_info.nickname}}</div>
              <div class="user_id">{{ this.user_info.user_id}}</div>
            </div>
            <div class="user_button" @click="updateUser">แก้ไข ID</div>
          </div>
          <div class="pay clearFix">
            <div class="pay_title">ช่องทาง</div>
            <div class="pay_main clearFix">
              
              <div class="pay_item" v-for="(item, index) in paymentList" :key="index"   @click="selectPayment(item.payment_key)">
                <div><img  v-if="activePayment == item.payment_key" :src="item.img_hover" alt=""> <img  v-if="activePayment != item.payment_key" :src="item.img" alt=""></div>
                <div v-if="activePayment == item.payment_key" class="choose" ><img src="../assets/image/choose.png" alt=""></div>
              </div>
            </div>
          </div>
          <div class="good">
            <div class="good_title">รายการชำระเงิน</div>
            <div class="good_content">
              <div class="good_big">
                <div class="good_big_item"  v-for="(item, index) in bigGoodsList" :key="index"  >
                  <div class="big_img">
                    
                    <img :src="item.thumb_icon" alt="">
                    <div class="big_limited" v-if="item.pay_count">จำกัด {{ item.pay_count }}/ {{ item.limit_num }}</div>
                    <div class="big_timing clearFix" v-if="item.time_left != 0">
                        <img src="../assets/image/lock.png" alt="">
                        <span><van-count-down @finish="getBigGoodsList" :time="item.time_left"  format="HH:mm:ss"/></span>
                    </div>
                  </div>
                  <div class="big_content">
                    <div class="big_title">{{item.title}}</div>
                    <div class="big_props">
                      <div class="big_props_item"   v-for="(itemChild, index) in item.give_goods" :key="index">
                        <div class="big_props_item_box">
                          <div class="big_props_item_img"><img :src="itemChild.packet_icon" alt=""></div>
                          <div class="big_props_item_num">{{itemChild.goods_num}}</div>
                        </div>
                        <div class="big_props_item_name">{{itemChild.goods_name}}</div>
                      </div>
                    </div>
                    <div class="big_sub">
                      <div class="big_button" @click="createOrder(item.mall_id)">{{item.price}}</div>
                      <div class="big_money">{{item.original_price}}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="good_middle clearFix">
                  <div class="middle_item"  v-for="(item, index) in middleGoodsList" :key="index" >
                    <div class="middle_img">
                      <img :src="item.thumb_icon" alt="">
                      <div class="middle_limited" v-if="item.pay_count">จำกัด {{ item.pay_count }}/{{ item.limit_num }}</div>
                      <div class="middle_timing clearFix" v-if="item.time_left != 0">
                          <img src="../assets/image/lock.png" alt="">
                          <span><van-count-down @finish="getMiddleGoodsList" :time="item.time_left"  format="HH:mm:ss"/></span>
                      </div>
                    </div>
                    <div class="middle_content">
                      <div class="middle_props">
                        <div class="middle_props_item"  v-for="(itemChild, index) in item.give_goods" :key="index">
                          <div class="middle_props_item_box">
                            <div class="middle_props_item_img"><img :src="itemChild.packet_icon" alt=""></div>
                            <div class="middle_props_item_num">{{itemChild.goods_num}}</div>
                          </div>
                          <div class="middle_props_item_name">{{itemChild.goods_name}}</div>
                        </div>
                      </div>
                      <div class="middle_sub">
                        <div class="middle_button" @click="createOrder(item.mall_id)">{{ item.price }}</div>
                        <div class="middle_money">{{item.original_price}}</div>
                      </div>
                    </div>
                  </div>
              </div>
              <div class="good_small clearFix">
                <div class="small_item" v-for="(item, index) in smallGoodsList" :key="index" >
                    <div class="small_img">
                      <img :src="item.thumb_icon" alt="">
                      <div class="small_limited" v-if="item.pay_count">จำกัด {{ item.pay_count }}/{{ item.limit_num }}</div>
                      <div class="small_timing clearFix" v-if="item.time_left != 0">
                          <img src="../assets/image/lock.png" alt="">
                          <span><van-count-down  @finish="getSmallGoodsList" :time="item.time_left"  format="HH:mm:ss"/></span>
                      </div>
                    </div>
                    <div class="small_content">
                      <div class="small_sub">
                        <div class="small_num">*{{item.give_goods[0].goods_num}}</div>
                        <div class="small_button" @click="createOrder(item.mall_id)">{{ item.price }}</div>
                        <div class="small_money">{{item.original_price}}</div>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        <FooterNav  :activeIndex="active"/>
        <FooterText />
    </div>
      
      
      
  </template>
  
  <script>
    import Top from "../components/Top.vue";
    import FooterText from "../components/FooterText.vue";
    import FooterNav from "../components/FooterNav.vue";
    import storage from "../../plugins/storage"

  export default {
    components: {
        FooterText,
        FooterNav,
        Top,
    },
    data() {  
      return {
        active: 1,
        activeTitle:0,
        userShow:true,
        user_id:'',
        user_info:{},
        paymentList:[],
        activePayment:'tiki',
        bigGoodsList:[],
        middleGoodsList:[],
        smallGoodsList:[],
        bigType:1,
        middleType:2,
        smallType:3,
        time:800000000,
        list:[
          {name:'เติมเงินทันที',url:'/good'},
          {name:'ช่วยเหลือ',url:'/help'},
        ]
      };
    },
    computed: {

    },
    mounted() {
      if(storage.get('userInfo')){
        this.user_info = storage.get('userInfo')
        this.userShow = false
      }
      // document.title = '我的新标题';
    },
    created() {
      if(storage.get('userInfo')){
        this.user_info = storage.get('userInfo')
        this.userShow = false
      }
      this.getBigGoodsList()
      this.getMiddleGoodsList()
      this.getSmallGoodsList()
      this.getPaymentList()
    },
    methods:{
      createOrder(mall_id){
        this.service.post('WebPayment/createOrder',{mall_id:mall_id, user_id: this.user_id})
        .then((res) => {
          if(res.data.status == 1){
            this.service.post('WebPayment/payment',{payment_key:this.activePayment,order_num:res.data.data.order_num})
            .then((res2) => {
              if(res2.data.status == 1){
                window.location.href = res2.data.data.payment_url;
              }else{
                this.$toast({
                  position: 'top',
                  message: res2.data.msg,
                })
              }
            })
          }else{
            this.$toast({
              position: 'top',
              message: res.data.msg,
            })
          }
        })
      },
      queryUserInfo(){
        this.service.post('Home/queryUserInfo',{user_id: this.user_id})
        .then((res) => {
          
          if(res.data.status == 1){
            this.user_info = res.data.data;
            storage.set('userInfo',res.data.data) 
            this.userShow = false
            this.getBigGoodsList()
            this.getMiddleGoodsList()
            this.getSmallGoodsList()
          }

          if(res.data.status != 1){
            this.userShow = true
            this.$toast({
              position: 'top',
              message: res.data.msg,
            })
          }
        })
      },
      updateUser(){
          this.userShow = true
          storage.remove('userInfo')
          this.getBigGoodsList()
          this.getMiddleGoodsList()
          this.getSmallGoodsList()
      },
      getPaymentList(){
        this.service.post('Home/getPaymentList')
        .then((res) => {
          if(res.data.status == 1){
            this.paymentList = res.data.data;
          }
        })
      },
      getBigGoodsList(){
        if(storage.get('userInfo') == null){
          this.user_id = ''
        }else{
          this.user_id = storage.get('userInfo').user_id;
        } 
        this.service.post('Home/getGoodsList',{type:this.bigType, user_id: this.user_id})
        .then((res) => {
          if(res.data.status == 1){
            this.bigGoodsList = res.data.data;
          }
        })
      },
      getMiddleGoodsList(){
        if(storage.get('userInfo') == null){
          this.user_id = ''
        }else{
          this.user_id = storage.get('userInfo').user_id;
        } 
        this.service.post('Home/getGoodsList',{type:this.middleType, user_id: this.user_id})
        .then((res) => {
          if(res.data.status == 1){
            this.middleGoodsList = res.data.data;
          }
        })
      },
      getSmallGoodsList(){
        if(storage.get('userInfo') == null){
          this.user_id = ''
        }else{
          this.user_id = storage.get('userInfo').user_id;
        } 
        this.service.post('Home/getGoodsList',{type:this.smallType, user_id: this.user_id})
        .then((res) => {
          if(res.data.status == 1){
            this.smallGoodsList = res.data.data;
          }
        })
      },
      selectPayment(index){
        this.activePayment = index
        // console.log(this.activePayment)
      }
    }
  };
  </script>
  
  <style scoped lang="less">
    .personal_head{
      margin-top: 40px;
      margin-bottom: 50px;
      width: 690px;
      height: 90px;
      .head_icon{
        width: 90px;
        height: 90px;
        float: left;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .head_input{
        width: 600px;
        float: left;
        height: 60px;
        margin-top: 15px;
        // overflow: hidden;
        input{
          margin-left: 20px;
          font-size: 30px;
          width: 420px;
          height: 60px;
          border-radius: 4px;
          border-color: #FFFFFF;
          line-height: 60px;
          border: 0;
          float: left;
          display: inline;
          box-sizing: border-box !important;
        }
        .head_button{
          display: block;
          float: right;
          width: 140px;
          height: 60px;
          line-height: 64px;
          text-align: center;
          background-color: #FFED50;
          font-size: 26px;
          font-weight: bolder;
          border-radius: 4px;
        }
      }
      
    }

    .user{
      margin-top: 40px;
      margin-bottom: 50px;
      width: 690px;
      height: 90px;
      .user_icon{
        width: 90px;
        height: 90px;
        float: left;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .user_detail{
        margin:0px 20px;
        margin-top: 15px;
        width: 420px;
        height: 64px;
        float: left;
        color: #FFFFFF;
        .user_name{
          font-size: 32px;
          line-height: 32px;
        }
        .user_id{
          font-size: 24px;
          font-weight: bolder;
          line-height: 36px;
        }
        input{
          font-size: 30px;
          width: 420px;
          height: 60px;
          border: 0px;
          outline: 0px;
          border-radius: 4px;
        }
      }
      .user_button{
        margin-top: 15px;
        float: left;
        width: 140px;
        height: 64px;
        line-height: 64px;
        text-align: center;
        background-color: #FFED50;
        font-size: 26px;
        font-weight: bolder;
        border-radius: 4px;
      }
    }
    .pay{
      width: 690px;
      margin-bottom: 20px;
      .pay_title{
        font-size: 46px;
        color: #FFFFFF;
        line-height: 40px;
        margin-bottom: 5px;
        font-weight: bold;
        font-family: 'kt';
      }
      .pay_main::after{
        display: block;
        clear: both;
        content: '';
      }
      .pay_main{
        width: 690px;
        
        .pay_item{
          width: 327px;
          height: 116px;
          background-color: rgba(255, 255, 255, 0.2);
          border-radius: 12px;
          border: 4px solid #95b7eb;
          float: left;
          margin-bottom: 20px;
          position: relative;
          img{
            width: 327px;
            height: 116px;
          }
          .choose{
            position: absolute;
            right: 0px;
            bottom: 0px;
            width: 38px;
            height: 38px;
            img{
              display: block;
              width: 100%;
              height: 100%;
            }
          }
        }
        .activePay{
            background-color: #FFFFFF!important;
            border: 4px solid #3DD0FF;
          }
        .pay_item:nth-child(2n){
          margin-left: 20px;
        }
      }
    }
    .good_title{
      font-size: 46px;
      color: #FFFFFF;
      line-height: 40px;
      margin-bottom: 5px;
      font-family: 'kt';
    }
    .good_big{
      .good_big_item{
        border:4px solid #FFFFFF;
        border-radius: 20px;
        width: 682px;
        height: 650px;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
        margin-bottom: 16px;
        .big_img{
          width: 682px;
          height: 330px;
          position: relative;
          
          img{
            border-top-right-radius: 20px;
            border-top-left-radius: 20px;
            width: 100%;
            height: 100%;
          }
          .big_limited{
            width: 120px;
            height: 35px;
            background: rgba(0, 0, 0, 0.5);
            text-align: center;
            line-height: 35px;
            font-size: 22px;
            color: #FFFFFF;
            position: absolute;
            right: 15px;
            top: 15px;
            border-radius: 20px;
          }
          .big_timing{
            width: 160px;
            height: 35px;
            background-color: #E22115;
            text-align: center;
            line-height: 35px;
            color: #FFED50;
            position: absolute;
            right: 0px;
            bottom: 15px;
            border-top-left-radius: 35px;
            border-bottom-left-radius: 35px;
            
            img{
              float: left;
                width: 26px;
                height: 26px;
                margin: 0px 10px;
                margin-top: 4px;
            }            
            span{
              text-align: center;
            }
            .van-count-down{
              height: 35px;
              float: left;
              line-height: 35px;
              font-size: 22px;
              color: #FFED50;
              text-align: center;
            }
          }
        }
        .big_content{
          border-bottom-right-radius: 15px;
          border-bottom-left-radius: 15px;
          width: 654px;
          height: 320px;
          background: url('../assets/image/gift.png') no-repeat;
          background-size: 320px 320px;
          background-position: right;
          padding-left: 30px;
          background-color: #FFFFFF;
          .big_title{
              font-size: 24px;
              color: #333333;
              line-height: 36px;
              padding: 15px 0px;
          }
          .big_props{
            display: flex;
            height: 155px;
            width: 652px;
            overflow: hidden;
            overflow-x: auto;
            white-space: nowrap;
            flex-direction: row;
            scrollbar-width: none;
            .big_props_item{
              width: 85px;
              height: 155px;
              margin-right: 16px;
              display: flex;
              align-items: center;
              flex-direction: column;
              .big_props_item_box{
                width: 85px;
                height: 85px;
                border-radius: 15px;
                background-color: rgba(61, 130, 255, 0.3);
                .big_props_item_img{
                  width: 60px;
                  height: 60px;
                  padding-left: 13px;
                  img{
                    width: 100%;
                    height: 100%;
                  }
                }
                .big_props_item_num{
                  font-size: 20px;
                  line-height: 25px;
                  color: #666666;
                  text-align: center;
                }
              }
              .big_props_item_name{
                width: 85px;
                height: 80px;
                font-size: 24px;
                color: #666666;
                word-break: break-all;
                word-wrap:break-all;
                white-space:normal;
              }
            }
          }
          .big_sub{
            width: 622px;
            height: 80px;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: flex-end;
            padding-top: 5px;
            .big_button{
              width: 220px;
              height: 50px;
              line-height: 50px;
              background:linear-gradient(to right,#357BFF,#37DCF3);
              color: #FFFFFF;
              text-align: center;
              font-size: 28px;
              border-radius: 25px;
              margin-bottom: 10px;
            }
            .big_money{
              width: 220px;
              color: #999999;
              text-align: center;
              font-size: 20px;
              text-decoration: line-through;
            }
          }
        }
        
      }
    }
    .good_middle{
      .middle_item{
        border:4px solid #FFFFFF;
        border-radius: 20px;
        width: 329px;
        height: 480px;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
        margin-bottom: 16px;
        float: left;
        .middle_img{
          width: 330px;
          height: 210px;
          position: relative;
          img{
            border-top-right-radius: 20px;
            border-top-left-radius: 20px;
            width: 100%;
            height: 100%;
          }
          .middle_limited{
            width: 120px;
            height: 35px;
            background: rgba(0, 0, 0, 0.5);
            text-align: center;
            line-height: 35px;
            font-size: 22px;
            color: #FFFFFF;
            position: absolute;
            left: 15px;
            bottom: 15px;
            border-radius: 20px;
          }
          .middle_timing{
            width: 160px;
            height: 35px;
            background-color: #E22115;
            text-align: center;
            line-height: 35px;
            color: #FFED50;
            position: absolute;
            right: 0px;
            bottom: 15px;
            border-top-left-radius: 35px;
            border-bottom-left-radius: 35px;
            img{
              float: left;
                width: 26px;
                height: 26px;
                margin: 0px 10px;
                margin-top: 4px;
            }
            span{
              text-align: center;;
            }            
            .van-count-down{
              height: 35px;
              float: left;
              line-height: 35px;
              font-size: 22px;
              color: #FFED50;
              text-align: center;
            }
          }
        }
        .middle_content{
          border-bottom-right-radius: 15px;
          border-bottom-left-radius: 15px;
          width: 316px;
          height: 270px;
          background: url('../assets/image/gift.png') no-repeat;
          background-size: 255px 255px;
          background-position: right;
          padding-left: 15px;
          background-color: #FFFFFF;
          .middle_props{
            display: flex;
            height: 160px;
            width: 322px;
            overflow: hidden;
            overflow-x: auto;
            white-space: nowrap;
            flex-direction: row;
            scrollbar-width: none;
            padding-top: 15px;
            .middle_props_item{
              width: 85px;
              height: 160px;
              margin-right: 16px;
              display: flex;
              align-items: center;
              flex-direction: column;
              .middle_props_item_box{
                width: 85px;
                height: 85px;
                text-align: center;
                border-radius: 15px;
                background-color: rgba(61, 130, 255, 0.3);
                .middle_props_item_img{
                  width: 60px;
                  height: 60px;
                  padding-left: 13px;
                  img{
                    width: 100%;
                    height: 100%;
                  }
                }
                .middle_props_item_num{
                  font-size: 20px;
                  color: #666666;
                  text-align: center;
                  line-height: 25px;
                }
              }
              .middle_props_item_name{
                height: 70px;
                text-align: center;
                font-size: 24px;
                color: #666666;
                overflow: hidden;
                word-break: break-all;
                word-wrap:break-all;
                white-space:normal;
              }
            }
          }
          .middle_sub{
            margin-top: 10px;
            width: 307px;
            height: 80px;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: center;
            .middle_button{
              width: 220px;
              height: 50px;
              line-height: 50px;
              background:linear-gradient(to right,#357BFF,#37DCF3);
              color: #FFFFFF;
              text-align: center;
              font-size: 28px;
              border-radius: 25px;
              margin-bottom: 10px;
            }
            .middle_money{
              width: 220px;
              color: #999999;
              text-align: center;
              font-size: 20px;
              text-decoration: line-through;
            }
          }
        }
      }
      .middle_item:nth-child(2n){
        margin-left: 15px;
      }
    }

    .good_small{
      .small_item{
        border-radius: 20px;
        width: 220px;
        height: 300px;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
        margin-right: 15px;
        margin-bottom: 16px;
        float: left;
        .small_img{
          width: 220px;
          height: 150px;
          position: relative;
          img{
            border-top-right-radius: 20px;
            border-top-left-radius: 20px;
            width: 100%;
            height: 100%;
          }
          .small_limited{
            width: 120px;
            height: 35px;
            background: rgba(0, 0, 0, 0.5);
            text-align: center;
            line-height: 35px;
            font-size: 22px;
            color: #FFFFFF;
            position: absolute;
            right: 10px;
            top: 10px;
            border-radius: 20px;
          }
          .small_timing{
            width: 160px;
            height: 35px;
            background-color: #E22115;
            text-align: center;
            line-height: 35px;
            color: #FFED50;
            position: absolute;
            right: 10px;
            top: 10px;
            border-radius: 35px;
            img{
              float: left;
                width: 26px;
                height: 26px;
                margin: 0px 10px;
                margin-top: 4px;
            }            
            .van-count-down{
              height: 35px;
              float: left;
              line-height: 35px;
              font-size: 22px;
              color: #FFED50;
              text-align: center;
            }

          }
        }
        .small_content{
          border-bottom-right-radius: 20px;
          border-bottom-left-radius: 20px;
          width: 220px;
          height: 150px;
          background-color: #FFFFFF;
          .small_sub{
            padding-top: 20px;
            width: 220px;
            height: 80px;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: center;
            .small_num{
              font-size: 24px;
              color: #666666;
              text-align: center;
              margin-bottom: 15px;
            }
            .small_button{
              width: 170px;
              height: 50px;
              line-height: 50px;
              background:linear-gradient(to right,#357BFF,#37DCF3);
              color: #FFFFFF;
              text-align: center;
              font-size: 28px;
              border-radius: 25px;
              margin-bottom: 10px;
              font-weight: bold;
            }
            .small_money{
              width: 170px;
              color: #999999;
              text-align: center;
              font-size: 20px;
              text-decoration: line-through;
            }
          }
        }
        
      }
      .small_item:nth-child(3n){
        margin-right: 0px;
      }
    }
  </style>
  
  